@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?uq3g3p');
  src:  url('fonts/icomoon.eot?uq3g3p#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?uq3g3p') format('truetype'),
    url('fonts/icomoon.woff?uq3g3p') format('woff'),
    url('fonts/icomoon.svg?uq3g3p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-image:before {
  content: "\e90d";
}
.icon-bullhorn:before {
  content: "\e91a";
}
.icon-connection:before {
  content: "\e91b";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-map:before {
  content: "\e94b";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-mobile2:before {
  content: "\e959";
}
.icon-drive:before {
  content: "\e963";
}
.icon-database:before {
  content: "\e964";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-users:before {
  content: "\e972";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner11:before {
  content: "\e984";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-menu2:before {
  content: "\e9be";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-plus:before {
  content: "\e9cf";
}
.icon-eye-minus:before {
  content: "\e9d0";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-sun:before {
  content: "\e9d4";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-arrow-up-left:before {
  content: "\ea31";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down-right:before {
  content: "\ea35";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-down-left:before {
  content: "\ea37";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up-left2:before {
  content: "\ea39";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-down-left2:before {
  content: "\ea3f";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-tab:before {
  content: "\ea45";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-terminal:before {
  content: "\ea81";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-trello:before {
  content: "\eab3";
}
